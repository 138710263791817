@if (src) {
  <div class="grid justify-center items-center text-5xl">
    @switch (type) {
      @case ("icon") {
        <mat-icon [inline]="inline">{{ src }}</mat-icon>
      }
      @case ("img") {
        <img
          class="object-contain w-full h-auto"
          loading="lazy"
          [src]="src"
          [alt]="alt"
          [title]="title"
        />
      }
      @case ("svg") {
        <svg-icon
          [applyClass]="true"
          class="w-full h-auto"
          [src]="src"
        ></svg-icon>
      }
    }
  </div>
}
