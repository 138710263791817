import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'sf-img',
  standalone: true,
  imports: [CommonModule, MatIconModule, SvgIconComponent],
  templateUrl: './img.component.html',
  styles: [
    `
      :host {
        max-height: 100%;
        max-width: 100%;
        display: grid;
      }
    `,
  ],
})
export class ImgComponent {
  @Input({
    transform: (value: string | object | undefined) => {
      if (!value) return value;
      if (typeof value === 'object') return value;
      // internet loaded
      if (value.startsWith('http')) return value;
      // local loaded
      // has extension
      if (value.includes('.')) return `assets/images/${value}`;
      // no extension - is an icon
      return value;
    },
  })
  src?: string;
  @Input({ required: false }) alt: string | undefined | null = null;
  @Input() title!: string;

  @Input() type: 'icon' | 'img' | 'svg' = 'img';
  @Input() inline: boolean = false;

  constructor() {}
}
